import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { api } from "../../../api";
import { WideLoader } from "../../../components/WideLoader";

class CompanyDetailedState {
  companyIds = [] as number[];
  companyName = "";
  companyDescription = "";
  companyLongDescription = "";
  companyTicker = "";
  longDescriptionUpdated = "";
  isLoading = true;
}

export const CompanyDetailed = () => {
  const [state, setState] = useState(new CompanyDetailedState());
  const { ticker } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticker) return;
    (async () => {
      try {
        const company = await api.getDetailedCompany(ticker);
        setState({
          ...state,
          companyIds: [company.id],
          companyName: company.name,
          companyTicker: company.ticker,
          companyDescription: company.description,
          companyLongDescription: company.longDescription,
          longDescriptionUpdated: company.longDescriptionUpdated,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [ticker]);

  if (state.isLoading) return <WideLoader />;
  if (!state.companyName && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>
          {state.companyName} ({state.companyTicker}) – Profil spółki
        </title>
        <meta name="description" content={state.companyDescription} />
        <link
          rel="canonical"
          href={`https://skaner.ai/c/${state.companyTicker.toLocaleLowerCase()}/profil`}
        />
      </Helmet>
      <div className="my-4 bg-white px-6 py-[2.125rem] lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7">
          <p className="text-base font-semibold leading-7 text-gray-500">
            Profil spółki
          </p>
          <Link
            to={`/c/${state.companyTicker.toLocaleLowerCase()}`}
            className="block w-full text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          >
            {state.companyName} ({state.companyTicker})
          </Link>
          <span className="text-sm leading-5 text-gray-500">
            opublikowano{" "}
            {new Date(state.longDescriptionUpdated).toLocaleDateString(
              "pl-PL",
              { month: "long", day: "numeric", year: "numeric" },
            )}
          </span>
          <div
            className="long-description"
            dangerouslySetInnerHTML={{ __html: state.companyLongDescription }}
          ></div>
          <div className="pt-6">
            <Link
              to={`/c/${state.companyTicker.toLocaleLowerCase()}`}
              className="font-semibold"
            >
              powrót do skanera spółki
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
