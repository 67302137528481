import { Footer } from "./components/Footer";
import { Outlet } from "react-router-dom";
import { Header } from "./components/Header";

export const Layout = () => {
  return (
    <div className="flex h-full w-full flex-col">
      <header>
        <Header />
      </header>
      <main className="flex w-full flex-grow items-center">
        <div className="block h-full w-full">
          <Outlet />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
