import { useRecoilState } from "recoil";
import { Company } from "../../../entities/company";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { selectedCompaniesAtom } from "../../../atoms/selectedCompaniesAtom";

class SelectedCompaniesParams {
  onChange?: () => void;
}

export const SelectedCompanies = ({ onChange }: SelectedCompaniesParams) => {
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesAtom,
  );

  const remove = (company: Company) => {
    setSelectedCompanies(selectedCompanies.filter((x) => x.id !== company.id));
    onChange?.();
  };

  return (
    <nav aria-label="Sidebar" className="flex flex-1 flex-col p-2">
      <ul className="-mx-2 space-y-1">
        {[...selectedCompanies]
          .sort((x: Company, y: Company) => x.name.localeCompare(y.name))
          .map((company) => (
            <li key={company.name}>
              <span
                onClick={() => remove(company)}
                className="group flex w-full cursor-pointer content-center items-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
              >
                {company.name}
                <div className="ml-auto w-5">
                  <XMarkIcon aria-hidden="true" className="ml-auto h-5 w-5" />
                </div>
              </span>
            </li>
          ))}
      </ul>
    </nav>
  );
};
