import { Post } from "../entities/post";
import { Link } from "react-router-dom";
import { Usefulness } from "../enums/usefulness";

interface CompactCompanyPostProps {
  post: Post;
  showCompanyNames: boolean;
}

export const CompactCompanyPost = (props: CompactCompanyPostProps) => {
  const { post } = props;

  const muted = (post: Post) => post.usefulness === Usefulness.Duplicate;
  const getPublishTime = (post: Post) => {
    const date = new Date(post.published);
    const minutes = date.getMinutes();
    return `${date.getHours()}:${minutes <= 9 ? `0${minutes}` : minutes}`;
  };

  return (
    <li
      key={post.id}
      className={`flex gap-x-6 py-0.5 ${muted(post) ? "opacity-55" : ""}`}
    >
      <div className="min-w-12 text-right">
        <Link
          to={`/s/${post.sourceSimplifiedName}`}
          title={post.sourceName}
          className="text-xs text-gray-400"
        >
          {post.sourceInitials}
        </Link>
      </div>
      <div className="flex-grow">
        <Link
          to={post.url}
          target="_blank"
          rel="noreferrer"
          className="text-xs font-semibold leading-6 text-gray-900"
        >
          {post.title}
        </Link>
        <span className="pl-3 text-xs text-gray-400">
          {getPublishTime(post)}
        </span>
        {/* <div className="text-black-500 mt-1 flex items-center gap-x-2 text-xs leading-5">
          <p className="whitespace-nowrap">{getPublishTime(post)}</p>
          {showCompanyNames && (
            <>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle r={1} cx={1} cy={1} />
              </svg>
              <p className="truncate">
                {intersperse(
                  post.companies.map((company) => (
                    <Link key={company.ticker} to={`/c/${company.ticker}`}>
                      {company.name}
                    </Link>
                  )),
                  <>, </>,
                )}
              </p>
            </>
          )}
        </div> */}
      </div>
    </li>
  );
};
