import { Helmet } from "react-helmet-async";
import { CompanyPosts } from "../../components/CompanyPosts";
import { useNavigate, useParams } from "react-router-dom";
import { sourceGroupApi } from "../../sourceGroupApi";
import { useEffect, useState } from "react";
import { WideLoader } from "../../components/WideLoader";

export const SourceScanner = () => {
  const [state, setState] = useState({
    id: 0,
    name: "",
    description: "",
    simplifiedName: "",
    isLoading: true,
  });
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!name) return;
    (async () => {
      try {
        const source = await sourceGroupApi.get(name);
        setState({
          ...source,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [name]);

  if (state.isLoading) return <WideLoader />;
  if (!state.name && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>{state.name} – Skaner.AI</title>
        <meta name="description" content={state.description} />
        <link
          rel="canonical"
          href={`https://skaner.ai/s/${state.simplifiedName}`}
        />
      </Helmet>
      <div className="flex h-full flex-col align-top">
        <div className="w-full border-b border-gray-100 py-4">
          <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              {state.name}
            </h1>
          </div>
          <div className="mx-auto mt-3 flex max-w-5xl px-4 text-sm leading-6 sm:px-6 lg:px-8">
            {state.description}
          </div>
        </div>
        <div className="flex-grow">
          <CompanyPosts
            configurable={false}
            showCompanyNames={true}
            sourceIds={[state.id]}
            postIds={[]}
            companyIds={[]}
          >
            <div className="w-full border-b border-gray-100 py-8">
              <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
                Brak wiadomości dla źródła
              </div>
            </div>
          </CompanyPosts>
        </div>
      </div>
    </>
  );
};
