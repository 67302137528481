import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNotifications } from "../../hooks/useNotifications";
import { NotificationType } from "../../enums/notification-type";
import { sourceGroupApi } from "../../sourceGroupApi";

export const SourceGroupDescriptionUpdate = () => {
  const { setNotification } = useNotifications();
  const [state, setState] = useState({
    id: 0,
    text: "",
    remaining: 0,
    loading: true,
  });
  const [description, setDescription] = useState("");

  const fetchTopDescriptionUpdate = () => {
    sourceGroupApi
      .getTopDescriptionUpdate()
      .then((response) => {
        setState({
          id: response.id,
          text: response.text,
          remaining: response.remaining,
          loading: false,
        });
      })
      .catch(() => {
        setState({
          ...state,
          loading: false,
        });
      });
  };

  useEffect(() => fetchTopDescriptionUpdate(), []);

  const save = () => {
    sourceGroupApi
      .patch(state.id, { description })
      .then(() => {
        setNotification("Zaktualizowano", NotificationType.Success);
        fetchTopDescriptionUpdate();
        setDescription("");
      })
      .catch(() => {
        setNotification(
          "Aktualizacja się nie powiodła",
          NotificationType.Error,
        );
      });
  };

  return (
    <>
      <Helmet>
        <title>Aktualizacja opisów źródeł – Skaner.AI</title>
        <meta
          name="description"
          content={`Wiadomoście giełdowe na temat spółek z GPW i NewConnect z kilkudziesięciu różnych źródeł.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl items-center px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Aktualizacja opisów źródeł
            </h1>
          </div>
          <div className="ml-auto min-w-28 text-right"></div>
        </div>
      </div>
      <div className="mx-auto w-full max-w-5xl px-4 py-[1.125rem] sm:px-6 lg:px-8">
        <div
          className="cursor-pointer"
          onClick={() => navigator.clipboard.writeText(state.text)}
        >
          {state.text}
        </div>

        <textarea
          value={description}
          onChange={(event) => setDescription(event.target.value ?? "")}
          className="mt-4 h-48 w-full rounded-md border-2 border-r-gray-300 p-4"
        ></textarea>
        <button
          onClick={save}
          type="button"
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Zapisz
        </button>
      </div>
    </>
  );
};
