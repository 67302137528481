import { Helmet } from "react-helmet-async";
import { CompanyPosts } from "../../components/CompanyPosts";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { NoBookmarks } from "./NoBookmarks";
import { RefreshIcon } from "./components/RefreshIcon";

export const Bookmarks = () => {
  const [bookmarkedStorage] = useLocalStorage<number[]>("bookmarkedPosts", []);
  return (
    <>
      <Helmet>
        <title>Zakładki – Skaner wiadomości</title>
        <meta
          name="description"
          content={`Wiadomoście giełdowe na temat spółek z GPW i NewConnect z kilkudziesięciu różnych źródeł.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl items-center px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Zakładki
            </h1>
          </div>
          <div className="ml-auto">
            {bookmarkedStorage.length > 0 && (
              <RefreshIcon
                onClick={() => {
                  console.log("todo");
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {bookmarkedStorage.length ? (
          <CompanyPosts
            configurable={false}
            showCompanyNames={true}
            sourceIds={[]}
            companyIds={[]}
            postIds={bookmarkedStorage}
          />
        ) : (
          <NoBookmarks />
        )}
      </div>
    </>
  );
};
