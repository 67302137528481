import { CompanyPosts } from "../../components/CompanyPosts";
import { ConfigPanel } from "./components/ConfigPanel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { NoCompanies } from "./components/NoCompanies";
import { NoPosts } from "./components/NoPosts";
import { Helmet } from "react-helmet-async";
import { useRecoilState } from "recoil";
import { selectedCompanyIdsAtom } from "../../atoms/selectedCompanyIdsAtom";
import { useEffect } from "react";

export const PortfolioScanner = () => {
  const storageKey = "selectedCompanies";
  const isFirstVisit = localStorage.getItem(storageKey) === null;
  const [storage, setStorage] = useLocalStorage<number[]>(storageKey, []);
  const [selectedCompanyIds, setSelectedCompanyIds] = useRecoilState(
    selectedCompanyIdsAtom,
  );

  useEffect(() => {
    if (selectedCompanyIds.length === 0) {
      if (isFirstVisit) {
        setSelectedCompanyIds([83, 105, 283]);
      } else if (storage.length > 0) {
        setSelectedCompanyIds(storage);
      }
    }
  }, []);

  useEffect(() => {
    setStorage(selectedCompanyIds);
  }, [selectedCompanyIds.join(",")]);

  return (
    <>
      <Helmet>
        <title>Skaner wiadomości giełdowych – Skaner.AI</title>
        <meta
          name="description"
          content="Skaner wiadomości giełdowych wykorzystujący sztuczną inteligencję, czerpiący informacje z kilkudziesięciu źródeł w tym serwisów specjalistycznych i branżowych. "
        />
        <link rel="canonical" href="https://skaner.ai" />
      </Helmet>
      {selectedCompanyIds.length === 0 ? (
        <NoCompanies />
      ) : (
        <CompanyPosts
          configurable={true}
          companyIds={selectedCompanyIds}
          postIds={[]}
          sourceIds={[]}
          showCompanyNames={true}
        >
          <NoPosts />
        </CompanyPosts>
      )}
      <ConfigPanel />
    </>
  );
};
