import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Company } from "../../../entities/company";
import { useRecoilState } from "recoil";
import { selectedCompaniesAtom } from "../../../atoms/selectedCompaniesAtom";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { api, cancelApiObject } from "../../../api";
import { Loader } from "../../../components/Loader";

class CompanySelectParams {
  onChange?: () => void;
}

export const CompanySelect = ({ onChange }: CompanySelectParams) => {
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesAtom,
  );
  const [availableCompanies, setAvailableCompanies] = useState([] as Company[]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useDebounce(true, 100);

  useEffect(() => {
    if (query.length <= 1) {
      setLoading(false);
      setAvailableCompanies([]);
      return;
    }

    (async () => {
      try {
        const companies = await api.searchCompanies(query);
        setAvailableCompanies(companies);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();

    return () => {
      cancelApiObject[api.searchCompanies.name].handleRequestCancellation();
    };
  }, [query]);

  const clear = () => {
    setAvailableCompanies([]);
    setQuery("");
    setLoading(false);
  };

  const applyQuery = (query: string) => {
    setQuery(query);
    setLoading(query.length > 0);
  };

  const setCompanies = (companies: Company[]) => {
    setQuery("");
    setLoading(false);
    setSelectedCompanies(companies);
  };

  return (
    <Combobox
      multiple={true}
      as="div"
      defaultValue={[]}
      key={selectedCompanies.length}
      autoFocus
      value={selectedCompanies}
      onClose={clear}
      onChange={setCompanies}
    >
      <div className="relative mt-2">
        <ComboboxInput
          autoFocus
          value={query}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          onChange={(event) => {
            applyQuery(event.target.value);
            onChange?.();
          }}
          displayValue={(company: Company) => company?.name}
          placeholder="Wpisz nazwę spółki aby wyszukać"
        />
        {loading && (
          <div className="absolute right-3 top-2" role="status">
            <Loader className="h-5 w-5" />
          </div>
        )}

        {availableCompanies.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {availableCompanies.map((company) => (
              <ComboboxOption
                key={company.id}
                value={company}
                className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {company.name}
                </span>
                <span className="absolute inset-y-0 left-0 hidden items-center pl-1.5 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}

        {availableCompanies.length === 0 && query.length > 2 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <p className="p-2 text-sm text-gray-500">Nie znaleziono spółki</p>
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
};
