import { Link, useNavigate, useParams } from "react-router-dom";
import { CompanyPosts } from "../../components/CompanyPosts";
import { useEffect, useState } from "react";
import { WideLoader } from "../../components/WideLoader";
import { api } from "../../api";
import {
  ArchiveBoxXMarkIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";
import { BarsArrowUpIcon } from "@heroicons/react/20/solid";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useRecoilState } from "recoil";
import { selectedCompanyIdsAtom } from "../../atoms/selectedCompanyIdsAtom";
import { useNotifications } from "../../hooks/useNotifications";
import { NotificationType } from "../../enums/notification-type";

class CompanyScannerState {
  companyIds = [] as number[];
  companyName = "";
  companyDescription = "";
  companyTicker = "";
  hasLongDescription = false;
  isLoading = true;
}

export const CompanyScanner = () => {
  const storageKey = "selectedCompanies";
  const isFirstVisit = localStorage.getItem(storageKey) === null;
  const [storage, setStorage] = useLocalStorage<number[]>(storageKey, []);
  const [state, setState] = useState(new CompanyScannerState());
  const { ticker } = useParams();
  const navigate = useNavigate();
  const { setNotification } = useNotifications();
  const [selectedCompanyIds, setSelectedCompanyIds] = useRecoilState(
    selectedCompanyIdsAtom,
  );
  const [showRemoveFromScanner, setShowRemoveFromScanner] = useState(false);

  useEffect(() => {
    if (selectedCompanyIds.length === 0) {
      if (isFirstVisit) {
        setSelectedCompanyIds([83, 105, 283]);
      } else if (storage.length > 0) {
        setSelectedCompanyIds(storage);
      }
    }
  }, []);

  useEffect(() => {
    setStorage(selectedCompanyIds);
  }, [selectedCompanyIds.join(",")]);

  useEffect(() => {
    if (!ticker) return;
    (async () => {
      try {
        const company = await api.getBasicCompany(ticker);
        setState({
          ...state,
          companyIds: [company.id],
          companyName: company.name,
          companyTicker: company.ticker,
          companyDescription: company.description,
          hasLongDescription: company.hasLongDescription,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [ticker]);

  const addToScanner = () => {
    setSelectedCompanyIds([...selectedCompanyIds, state.companyIds[0]]);
    setNotification(
      "Spółka została dodana do skanera",
      NotificationType.Success,
    );
  };

  const removeFromScanner = () => {
    setSelectedCompanyIds([
      ...selectedCompanyIds.filter((id) => id !== state.companyIds[0]),
    ]);
    setNotification(
      "Spółka została usunięta ze skanera",
      NotificationType.Success,
    );
  };

  if (state.isLoading) return <WideLoader />;
  if (!state.companyName && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>
          {state.companyName} ({state.companyTicker}) – Skaner.AI
        </title>
        <meta name="description" content={state.companyDescription} />
        <link
          rel="canonical"
          href={`https://skaner.ai/c/${state.companyTicker.toLocaleLowerCase()}`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
          <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
            {state.companyName} ({state.companyTicker})
          </h1>
          <div className="ml-auto">
            {selectedCompanyIds.includes(state.companyIds[0]) ? (
              <button
                type="button"
                onClick={() => {
                  removeFromScanner();
                }}
                onMouseOver={() => {
                  setShowRemoveFromScanner(true);
                }}
                onMouseOut={() => {
                  setShowRemoveFromScanner(false);
                }}
                className="rounded-md bg-green-600 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                {showRemoveFromScanner ? (
                  <XMarkIcon aria-hidden="true" className="h-4 w-4" />
                ) : (
                  <CheckIcon aria-hidden="true" className="h-4 w-4" />
                )}{" "}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  addToScanner();
                }}
                className="rounded-md bg-zinc-600 px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                <BarsArrowUpIcon aria-hidden="true" className="h-4 w-4" />{" "}
              </button>
            )}
          </div>
        </div>
        <div className="mx-auto mt-3 flex max-w-5xl px-4 text-sm leading-6 sm:px-6 lg:px-8">
          <p>
            {state.companyDescription}{" "}
            <Link
              to={`/c/${state.companyTicker.toLocaleLowerCase()}/profil`}
              className="font-semibold"
            >
              Profil spółki
            </Link>
            .
          </p>
        </div>
      </div>
      <div>
        {!state.isLoading && state.companyIds.length > 0 && (
          <CompanyPosts
            configurable={false}
            companyIds={state.companyIds}
            sourceIds={[]}
            postIds={[]}
            showCompanyNames={false}
          >
            <div className="my-16 text-center">
              <ArchiveBoxXMarkIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Brak wiadomości dla wybranej spółki
              </h3>
            </div>
          </CompanyPosts>
        )}
      </div>
    </>
  );
};
