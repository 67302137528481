import { Loader } from "./Loader";

export const WideLoader = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto my-24 w-8" role="status">
        <Loader className="h-8 w-8" />
      </div>
    </div>
  );
};
