import { axiosApi } from "./axiosConfig";
import { defineCancelApiObject } from "./axiosUtils";
import { SourceGroup } from "./entities/source-group";

export const sourceGroupApi = {
  getTopDescriptionUpdate: async function (
    cancel = false,
  ): Promise<{ id: number; text: string; remaining: number }> {
    const response = await axiosApi.request({
      url: `admin/SourceGroup/top-description-update`,
      method: "GET",
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[
            this.getTopDescriptionUpdate.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  patch: async function (
    id: number,
    fields: { description?: string },
    cancel = false,
  ): Promise<boolean> {
    const response = await axiosApi.request({
      url: "admin/SourceGroup",
      method: "PATCH",
      data: { id, ...fields },
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[this.patch.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  get: async function (
    sourceGroupSimplifiedName: string,
    cancel = false,
  ): Promise<SourceGroup> {
    const response = await axiosApi.request({
      url: `public/source-group?simplifiedName=${sourceGroupSimplifiedName}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const cancelApiObject = defineCancelApiObject(sourceGroupApi);
