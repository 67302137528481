import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CompanySelect } from "./CompanySelect";
import { SelectedCompanies } from "./SelectedCompanies";
import { useRecoilState } from "recoil";
import { isConfigOpenedAtom } from "../../../atoms/isConfigOpenedAtom";
import { selectedCompaniesAtom } from "../../../atoms/selectedCompaniesAtom";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { WideLoader } from "../../../components/WideLoader";
import { useDebounce } from "use-debounce";
import { selectedCompanyIdsAtom } from "../../../atoms/selectedCompanyIdsAtom";

export const ConfigPanel = () => {
  const [isConfigOpened, setIsConfigOpened] =
    useRecoilState(isConfigOpenedAtom);
  const [selectedCompanyIds, setSelectedCompanyIds] = useRecoilState(
    selectedCompanyIdsAtom,
  );
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesAtom,
  );
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useDebounce(true, 500);

  useEffect(() => {
    if (!isConfigOpened) return;

    if (selectedCompanyIds.length === 0) {
      setLoading(false);
      return;
    }

    setLoading(true);

    (async () => {
      try {
        const companies = await api.getCompanies(selectedCompanyIds);
        setSelectedCompanies(companies);
        setLoading(false);
      } catch {
        setSelectedCompanies([]);
        setLoading(false);
      }
    })();
  }, [selectedCompanyIds.join(","), isConfigOpened]);

  const close = () => {
    setIsConfigOpened(false);

    if (updated) {
      setSelectedCompanyIds(selectedCompanies.map((x) => x.id));
    }

    setTimeout(() => {
      setSelectedCompanies([]);
    }, 500);
  };

  return (
    <Dialog open={isConfigOpened} onClose={close} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        Dodaj lub usuń spółkę
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={close}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Zamknij</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-2 flex-1 px-4 sm:px-6">
                    <CompanySelect onChange={() => setUpdated(true)} />
                    <div className="mt-2">
                      {loading ? (
                        <WideLoader />
                      ) : (
                        <SelectedCompanies onChange={() => setUpdated(true)} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
