import { SignalSlashIcon } from "@heroicons/react/24/outline";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useEffect } from "react";
import { usePostsNotifier } from "../../../hooks/usePostsNotifier";

interface PostsErrorParams {
  retry: () => void;
}

export const PostsError = ({ retry }: PostsErrorParams) => {
  usePostsNotifier(() => retry());

  return (
    <div className="mx-auto my-12 text-center">
      <SignalSlashIcon className="mx-auto h-14 w-14 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        Wystpił błąd podczas pobierania wiadomości
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Kiedy połączenie zostanie wznowione, wiadomości załadują się
        automatycznie
      </p>
    </div>
  );
};
